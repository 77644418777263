import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../utils/requests";

function Statistics() {
  const [fakeData, setFakeData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getRequest("/user/info/my-orders", dispatch)
      // getRequest("/user/info/all-books", dispatch)
      .then((res) => {
        setFakeData(res.data);
      })
      .catch((log) => {
        console.log(log);
      });
  }, []);
  function RowTable({ item }) {
    const [recivedDate, setRecivedDate] = useState("");
    const [returnDate, setReturnDate] = useState("");
    useEffect(() => {
      const date = new Date(item.date_received);
      setRecivedDate(date.toLocaleDateString());
      date.setDate(date.getDate() + 30);
      setReturnDate(date.toLocaleDateString());
    }, []);
    const status = {
      ready: {
        name: "Tayyorlandi",
        color: "green",
      },
      block: {
        name: "Bloklandingiz",
        color: "red",
      },
      waiting: {
        name: "Kutilmoqda",
        color: "orange",
      },
      reading: {
        name: "O'qilmoqda",
        color: "teal",
      },
      not_return: {
        name: "Qaytarilmadi",
        color: "white",
      },
      returned: {
        name: "Qaytarildi",
        color: "lime",
      },
    };
    return (
      <div>
       
        <div
          className={`bg-[#F6F6F5] grid md:grid-cols-4 grid-cols-2 md:text-center md:p-[.5vw] p-[2.5vw] md:rounded-full rounded-[2vw] font-medium md:gap-0 gap-[1vw]`}
        >
          <p>
            <span className="md:hidden">Kitob nomi: </span>
            {item?.book?.name}
          </p>
          <p>
            <span className="md:hidden">Olingan sana: </span>
            {recivedDate}
          </p>
          <p>
            <span className="md:hidden">Qaytarish sanasi: </span>
            {returnDate}
          </p>
          <p
            // className={`text-[${status[item?.status].color}]`}
            style={{ color: status[item?.status].color }}
          >
            {status[item?.status].name}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="bg-white rounded-[1vw] p-[2.25vw]">
        <h1 className="font-medium md:text-[2vw] text-[6vw] md:mt-[0vw] mt-[6vw]">
          Hisobotlar
        </h1>
        <div className="grid md:mt-[2vw] mt-[4vw] gap-[1vw] whitespace-nowrap md:text-[.9vw] text-[2.9vw]">
          <div className="bg-[#F6F6F5] md:grid hidden grid-cols-4 md:text-center p-[.5vw] md:rounded-full rounded-[2vw] font-medium md:gap-0 gap-[1vw]">
            <p>Kitob nomi:</p>
            <p>Band qilingan san'a:</p>
            <p>Qaytarilish sanasi:</p>
            <p>Status</p>
          </div>
          {fakeData?.map((item, index) => (
            <RowTable key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Statistics;
