import React, { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import { getRequest } from "../utils/requests";
import { useDispatch } from "react-redux";
import { useAssistans } from "../redux/selectors";

function SelectBook() {
  const resursSohasi = [
    "Adabiyot",
    "AKT",
    "Arxeologiya",
    "Astronomiya",

    "Biologiya",
    "Ekologiya",
    "Avtomatika",
    "Avtomobilsozlik",
    "Avtotransport",
    "Agronomiya",
    "Anatomiya",
    "Arxitektura",
    "Arxiv",
    "Arxivshunoslik",
    "Baliq xo'jaligi",
    "Biologiya",
    "Buxgalteriya",
    "Biotexnologiya",
    "Davlat va huquq",
    "Dizayn",
    "Diplomatika",
    "Dinshunoslik",
    "Din va madaniyat",
    "Dunyo siyosati",
    "Dunyo tillari",
    "Dunyo adabiyoti",
    "Elektronika",
    "Energetika",
    "Ekologiya",
    "Ekonomika",
    "Fizika",
    "Geodeziya",
    "Geografiya",
    "Gidrologiya",
    "Gastronomiya",
    "Gumanitar soha",
    "Grafika",
    "Geofizika",
    "Iqtisodiyot",
    "Informatika",
    "Ijtimoiy soha",
    "Ijtimoiy siyosat",
    "Ijtimoiy psixologiya",
    "Ijtimoiy axloq",
    "Ijtimoiy texnologiya",
    "Ijtimoiy xizmat",
    "Ijtimoiy madaniyat",
    "Ijtimoiy islohotlar",
    "Marketing",
    "Matematika",
    "Musiqa",
    "Menejment",
    "Moliya",
    "Mikrobiologiya",
    "Maktabgacha ta'lim",
    "Mehanika",
    "Makroiqtisodiyot",
    "Tibbiyot",
    "Texnologiya",
    "Tilshunoslik",
    "Tarix",
    "Yadro muhandislik",
    "Yurisprudensiya",
    "Yozuvshunoslik",
    "Yengil sanoat",
  ];
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [isNull, setIsNull] = useState(false);
  const [pagination, setPagination] = useState(6);
  const dispatch = useDispatch();
  const { searchValue } = useAssistans();
  const getData = () => {
    getRequest("/user/info/all-books", dispatch)
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const filterBooks = (e) => {
    e.preventDefault();
    filterFunction(e);
  };

  const filterFunction = (e) => {
    const form = e.target;
    const formData = new FormData(form);
    const filData = {};
    formData.forEach((value, key) => {
      filData[key] = value;
    });
    console.log(
      JSON.stringify({
        type: filData.type,
        isbn: filData.isbn,
        resurs_turi: filData.resurs_turi,
        resurs_sohasi: filData.resurs_sohasi,
        date_book: filData.date_book,
      })
    );

    setFilter([]);
    data?.map((item) => {
      if (true) {
        setFilter((state) => [...state, item]);
      }
    });
  };

  const filterYear = (e) => {
    setFilter([]);
    const b = [];
    data?.map((item) => {
      if (e.target.value == item.date_book) {
        setFilter((state) => [...state, item]);
        b.push(item);
      }
    });
    if (b.length === 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }
  };
  const filterISBN = (e) => {
    setFilter([]);
    const b = [];
    data?.map((item) => {
      const content = JSON.parse(item.type);
      console.log(e.target.value, content.isbn);
      if (e.target.value == content.isbn) {
        setFilter((state) => [...state, item]);
        b.push(item);
      }
    });
    if (b.length === 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }
  };

  const filterResurs = (e) => {
    setFilter([]);
    const b = [];
    data?.map((item) => {
      const content = JSON.parse(item.type);
      if (e.target.value == content.resurs_turi) {
        setFilter((state) => [...state, item]);
        b.push(item);
      }
    });
    if (b.length === 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }
  };
  const filterLang = (e) => {
    setFilter([]);
    const b = [];
    data?.map((item) => {
      const content = JSON.parse(item.type);
      if (e.target.value == content.type) {
        setFilter((state) => [...state, item]);
        b.push(item);
      }
    });
    if (b.length === 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }
  };

  const filterDirection = (e) => {
    setFilter([]);
    const b = [];
    data?.map((item) => {
      const content = JSON.parse(item.type);
      if (e.target.value == content.resurs_sohasi) {
        setFilter((state) => [...state, item]);
        b.push(item);
      }
    });
    if (b.length === 0) {
      setIsNull(true);
    } else {
      setIsNull(false);
    }
  };
  const inputStyle = "border bg-gray-100 p-[.5vw] rounded-[.5vw] outline-none";

  return (
    <div>
      <h1 className="font-medium md:text-[2vw] text-[6vw] md:mt-[3vw] mt-[6vw] mb-[2vw]">
        Mavjud kitoblar
      </h1>
      <p>Filter:</p>
      <form
        onSubmit={filterBooks}
        className="grid grid-cols-6 gap-[2vw] text-[1vw] my-[1vw] items-center  "
      >
        <select
          name="date_book"
          className="border bg-gray-100 p-[.5vw] rounded-[.5vw] outline-none"
          // required
          onChange={filterYear}
        >
          <option value="">Nashr etilgan yil</option>
          {[...Array(100)].map((_, i) => (
            <option value={i + 1924}>{i + 1924}</option>
          ))}
        </select>
        <input
          type="number"
          className={inputStyle}
          placeholder="ISBN Kodi:"
          onChange={filterISBN}
          name="isbn"
        />
        <select
          name="resurs_turi"
          onChange={filterResurs}
          id=""
          className={inputStyle}
        >
          <option value="">Resurs turi</option>
          <option value="darslik">Darslik</option>
          <option value="uslubiy_korsatma">Uslubiy ko'rsatma</option>
          <option value="oquv_qollanma">O'quv qo'llanma</option>
          <option value="badiiy_adabiyot">Badiiy adabiyot</option>
          <option value="uslubiy_qollanma">Uslubiy qo'llanma</option>
          <option value="lugat">Lug'at</option>
          <option value="malumotnoma">Ma'lumotnoma</option>
          <option value="maruza">Maruzalar to'plami</option>
        </select>
        <select name="type" id="" onChange={filterLang} className={inputStyle}>
          <option value="">Resurs tili</option>
          <option value="uzbek">O'zbek</option>
          <option value="russian">Rus</option>
          <option value="foreign">Ingliz</option>
          <option value="other">Boshqa</option>
        </select>
        <select
          name="resurs_sohasi"
          id=""
          onChange={filterDirection}
          className={inputStyle}
        >
          <option value="">Resurs sohasi</option>
          {resursSohasi?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        <button
          type="reset"
          onClick={() => {
            setIsNull(false);
          }}
          className="bg-[#FB6D3A] text-white p-[.5vw] rounded-[.5vw] outline-none"
        >
          Tozalash
        </button>
      </form>
      {searchValue?.length > 0 ? (
        <div className="grid md:grid-cols-3 grid-cols-2 md:gap-[1vw] gap-[3vw] md:mt-[2vw] mt-[4vw]">
          {data?.map((item, i) => {
            if (
              item?.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
              item?.writer?.toLowerCase()?.includes(searchValue.toLowerCase())
            ) {
              return (
                <Link
                  to={"/book/" + item?.id}
                  key={i}
                  className="w-full md:h-[18vw] h-[48vw] rounded-[2vw] overflow-hidden relative md:p-[1vw] p-[3vw] flex items-end"
                >
                  <img
                    src={require("./assets/images/loader.gif")}
                    alt=""
                    className="absolute w-full h-full object-contain top-0 left-0 bg-[#f1fafe]"
                  />
                  <img
                    src={
                      item.image !== "false" &&   item.image !== null &&   item.image !== null
                        ? "https://ebook.firstcoders.site" + item?.image
                        : "https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books-illustration_23-2149341898.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1713225600&semt=sph"
                    }
                    alt="book"
                    className="absolute w-full h-full object-cover top-0 left-0"
                  />

                  <div className="flex flex-col relative z-[4] text-white backdrop-blur-[1vw] md:p-[1vw] p-[3vw] md:rounded-[.5vw] rounded-[2.5vw] w-full  bg-[#00000062]">
                    <h1 className="md:text-[1vw] text-[4vw] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                      {item?.name}
                    </h1>
                    <h2 className="md:text-[.8vw] text-[2.8vw] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item?.writer}
                    </h2>
                  </div>
                </Link>
              );
            }
          })}
        </div>
      ) : filter?.length > 0 ? (
        <div className="grid md:grid-cols-3 grid-cols-2 md:gap-[1vw] gap-[3vw] md:mt-[2vw] mt-[4vw]">
          {filter
            ?.filter(
              (item) =>
                item?.name?.toLowerCase()?.includes(searchValue) ||
                item?.writer?.toLowerCase()?.includes(searchValue)
            )
            .slice(0, pagination)
            ?.map((item, i) => {
              return (
                <Link
                  to={"/book/" + item?.id}
                  key={i}
                  className="w-full md:h-[18vw] h-[48vw] rounded-[2vw] overflow-hidden relative md:p-[1vw] p-[3vw] flex items-end"
                >
                  <img
                    src={require("./assets/images/loader.gif")}
                    alt=""
                    className="absolute w-full h-full object-contain top-0 left-0 bg-[#f1fafe]"
                  />
                  <img
                    src={
                      item.image !== "false" &&   item.image !== null &&   item.image !== null
                        ? "https://ebook.firstcoders.site" + item?.image
                        : "https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books-illustration_23-2149341898.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1713225600&semt=sph"
                    }
                    alt="book"
                    className="absolute w-full h-full object-cover top-0 left-0"
                  />

                  <div className="flex flex-col relative z-[4] text-white backdrop-blur-[1vw] md:p-[1vw] p-[3vw] md:rounded-[.5vw] rounded-[2.5vw] w-full  bg-[#00000062]">
                    <h1 className="md:text-[1vw] text-[4vw] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                      {item?.name}
                    </h1>
                    <h2 className="md:text-[.8vw] text-[2.8vw] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item?.writer}
                    </h2>
                  </div>
                </Link>
              );
            })}
        </div>
      ) : data?.length > 0 && !isNull ? (
        <div className="grid md:grid-cols-3 grid-cols-2 md:gap-[1vw] gap-[3vw] md:mt-[2vw] mt-[4vw]">
          {data?.slice(0, pagination)?.map((item, i) => {
            return (
              <Link
                to={"/book/" + item?.id}
                key={i}
                className="w-full md:h-[18vw] h-[48vw] rounded-[2vw] overflow-hidden relative md:p-[1vw] p-[3vw] flex items-end"
              >
                <img
                  src={require("./assets/images/loader.gif")}
                  alt=""
                  className="absolute w-full h-full object-contain top-0 left-0 bg-[#f1fafe]"
                />
                <img
                  src={
                    item.image !== "false" &&   item.image !== null &&   item.image !== null
                      ? "https://ebook.firstcoders.site" + item?.image
                      : "https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books-illustration_23-2149341898.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1713225600&semt=sph"
                  }
                  alt="book"
                  className="absolute w-full h-full object-cover top-0 left-0"
                />

                <div className="flex flex-col relative z-[4] text-white backdrop-blur-[1vw] md:p-[1vw] p-[3vw] md:rounded-[.5vw] rounded-[2.5vw] w-full  bg-[#00000062]">
                  <h1 className="md:text-[1vw] text-[4vw] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.name}
                  </h1>
                  <h2 className="md:text-[.8vw] text-[2.8vw] whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.writer}
                  </h2>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {isNull && (
        <h1 className="md:text-[1.6vw] md:mt-[3vw]">Kitoblar mavjud emas :)</h1>
      )}
      <div className="flex justify-center">
        <button
          className="md:py-[.4vw] py-[2.4vw] md:rounded-[.4vw] rounded-[3.4vw]  md:text-[.9vw] text-[3.9vw] md:px-[0.52vw] px-[3.52vw] bg-[#FB6D3A]  w-auto mt-[3vw] md:mt-[6vw] text-white "
          onClick={() => setPagination(pagination + 6)}
        >
          Ko'proq ko'rsatish
        </button>
      </div>
    </div>
  );
}

export default SelectBook;
