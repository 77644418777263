import React, { useState, useRef, useEffect } from "react";
import { getRequest, postRequestGPT } from "../utils/requests";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
function AIChat() {
  const windowChat = useRef(null);
  const inputStyle =
    "border border-black md:rounded-[1vw] rounded-[3vw] bg-[#F6F6F5] md:p-[1vw] p-[4vw] md:text-[1vw] text-[3vw] w-full outline-[#FB6D3A]";
  const [messages, setMessages] = useState(
    JSON.parse(localStorage.getItem("messages")) || [
      {
        message: "Salom! savolingiz bo`lsa yozing!",
        role: "ai",
      },
    ]
  );
  const [loading, setLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const getData = () => {
    getRequest("/user", dispatch)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const sendSMS = (sentText) => {
    const data = {
      // Other parameters as required by the API
      model: "gpt-4-turbo-preview",
      // model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: [
            {
              type: "text",
              text: `${sentText} `,
            },
          ],
        },
      ],
      // max_tokens: 100,
    };
    setLoading(true);
    postRequestGPT(data)
      .then((res) => {
        setLoading(false);

        setSendingMessage("");
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: res.choices[0].message.content,
            role: "ai",
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    windowChat.current.scrollTop = windowChat.current.scrollHeight + 900;
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);
  return (
    <div>
      <h1 className="font-medium md:text-[2vw] text-[6vw] md:my-0 my-[4vw]">
        AI bilan suxbat
      </h1>
      <div className="relative">
        <button
          className="absolute right-0 md:top-[-4vw] top-[-10vw] "
          onClick={() => {
            if (window.confirm("Siz AI bilan chatni o'chirmoqchimisiz?")) {
              localStorage.removeItem("messages");
              setMessages([
                {
                  message: "Salom! savolingiz bo`lsa yozing!",
                  role: "ai",
                },
              ]);
            }
          }}
        >
          <AiOutlineDelete className="md:text-[2vw] text-[8vw]" />
        </button>
        <div
          className="md:h-[30vw] h-[70vh] bg-[#f0f0f093] rounded-[2vw] my-[2vw] border border-black overflow-auto scroll-hidden"
          ref={windowChat}
        >
          {messages?.map((message, index) =>
            message?.role === "user" ? (
              <div
                key={index}
                className="p-[1vw] flex justify-end items-start md:gap-[.4vw] gap-[2.4vw]"
              >
                <div className="bg-[#Ffffff] md:rounded-[1vw] rounded-[3vw] md:p-[1vw] p-[2vw] md:text-[1vw] text-[3vw] text-black border border-gray-500 inline-block rounded-tr-none md:mt-[3vw] mt-[6vw]  md:max-w-[40vw] max-w-[74vw] overflow-hidden">
                  {message?.message}
                </div>
                <img
                  src={
                    data?.photo
                      ? `https://ebook.firstcoders.site${data?.photo}`
                      : "https://as2.ftcdn.net/v2/jpg/03/49/49/79/1000_F_349497933_Ly4im8BDmHLaLzgyKg2f2yZOvJjBtlw5.jpg"
                  }
                  alt="alt"
                  className="md:w-[4vw] md:h-[4vw] w-[11vw] h-[11vw] rounded-full object-cover"
                />
              </div>
            ) : (
              <div
                key={index}
                className="p-[1vw] flex justify-start gap-[.3vw] items-start"
              >
                <img
                  src={require("./assets/images/bot.png")}
                  alt="alt"
                  className="md:w-[4vw] md:h-[4vw] w-[11vw] h-[11vw] rounded-full  "
                />
                <div className="bg-[#Ffffff] md:rounded-[1vw] rounded-[3vw] md:p-[1vw] p-[2vw] md:text-[1vw] text-[3vw] text-black border border-gray-500 inline-block md:rounded-tl-none rounded-tl-none md:mt-[3vw] mt-[6vw]  md:max-w-[40vw] max-w-[74vw] overflow-hidden">
                  {message?.message}
                </div>
              </div>
            )
          )}
          {loading && (
            <div className="p-[1vw] flex justify-start gap-[.3vw] items-start">
              <img
                src={require("./assets/images/bot.png")}
                alt="alt"
                className="md:w-[4vw] md:h-[4vw] w-[11vw] h-[11vw] rounded-full loader-image"
              />
              <div className="bg-[#Ffffff] md:rounded-[1vw] rounded-[3vw] md:p-[1vw] p-[2vw] md:text-[1vw] text-[3vw] text-black border border-gray-500 inline-block rounded-tl-none md:mt-[3vw] mt-[6vw]  md:max-w-[40vw] max-w-[74vw] overflow-hidden">
                Iltimos biroz kuting....
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <textarea
          className={inputStyle}
          rows={1}
          placeholder="Savol"
          disabled={loading}
          value={sendingMessage}
          onChange={(e) => {
            setSendingMessage(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setMessages((prevMessages) => [
                ...prevMessages,
                { role: "user", message: sendingMessage },
              ]);
              sendSMS(e.target.value, messages);
              setSendingMessage("");
              e.target.value = "";
            }
          }}
        ></textarea>
        {loading ? (
          <button className="bg-[#848484] md:rounded-[1vw] rounded-[3vw] md:p-[1vw] p-[4vw] ml-[1vw] text-white flex items-center justify-center md:w-[8vw] md:h-[4vw] w-[13w] h-[13vw]">
            <span className="loader-btn"></span>
          </button>
        ) : (
          <button
            className="bg-[#FB6D3A] md:rounded-[1vw] rounded-[3vw] md:p-[1vw] p-[4vw] md:text-[1vw] text-[3vw] ml-[1vw] text-white"
            onClick={() => {
              setMessages([
                ...messages,
                { role: "user", message: sendingMessage },
              ]);
              sendSMS(sendingMessage, messages);
            }}
          >
            Yuborish
          </button>
        )}
      </div>
    </div>
  );
}

export default AIChat;
