import React from "react";

function Loader() {
  return (
    <div className="w-full h-screen flex items-center justify-center fixed z-[99] left-0 top-0 bg-[#00000068] backdrop-blur-sm">
      <span className="loader"></span>
    </div>
  );
}

export default Loader;
