import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import { routes } from "./utils/routes";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
const router = createBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="949505509190-cs6polg2bnndp8tg97s04s5754hj8bhe.apps.googleusercontent.com">
      <Provider store={store}>
        <App router={router} />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
