import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../utils/requests";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function SignUpComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    postRequest("/auth/user-register", data, dispatch)
      .then((res) => {
        if (res.status) {
          navigate("/login");
          toast.success("Hisob yaratildi");
        }else{
          window.alert("Kiritilgan ma'lumot bazada mavjud")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="bg-white h-full  py-[60px]">
      <form
        onSubmit={handleSubmit}
        className="h-full flex flex-col p-5 items-center justify-center gap-5"
      >
        <input
          type="text"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Foydalanuvchi nomi"
          name="user_name"
        />
        <input
          type="text"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Ismi va Familiyasi"
          name="full_name"
        />
        <input
          type="text"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Elektron pochta manzili"
          name="email"
        />
        <input
          type="password"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Maxfiy so’z"
          name="password"
        />
        {/* <input
          type="password"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Maxfiy so'z (takroran)"

        /> */}
        <button className="p-[18px] text-white bg-[#FB6D3A] hover:bg-[#d84646] active:scale-[.95] rounded-[20px] w-full max-w-[310px]">
          Hisob yaratish
        </button>
        <h2 className="font-semibold text-[16px]">
          Allaqachon hisob mavjudmi?{" "}
          <Link to={"/login"} className="text-[#00DF76]">
            Kirish
          </Link>
        </h2>
      </form>
    </div>
  );
}

export default SignUpComponent;
