import React from "react";
import { RouterProvider } from "react-router-dom";
import Loader from "./components/elements/Loader";
import { useAssistans } from "./redux/selectors";
import { Toaster } from "react-hot-toast";

function App({ router }) {
  const { isLoader } = useAssistans();
  return (
    <div className="bg-[#503E9D]">
      <Toaster />
      {isLoader && <Loader />}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
