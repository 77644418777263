import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../utils/requests";
import { authChange, setUser } from "../redux/slices/auth";

function LoginComponent() {
  // giva@mailinator.com

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    postRequest("/auth/login", data, dispatch)
      .then((res) => {
        console.log(res);
        if (res.status) {
          dispatch(authChange(true));
          dispatch(setUser(res));
          localStorage.setItem("token", res.token);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="bg-white h-full ">
      <form
        onSubmit={handleSubmit}
        className="h-full flex flex-col p-5 items-center justify-center gap-5"
      >
        <img
          src={require("./assets/images/login.png")}
          className="w-full max-w-[400px]"
          alt="login"
        />
        <input
          type="text"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="E-mail"
          name="email"
        />
        <input
          type="password"
          className="border border-black rounded-[20px] bg-[#F6F6F5] p-[18px] w-full max-w-[500px] outline-[#FB6D3A] text-[16px]"
          placeholder="Maxfiy so'z"
          name="password"
        />
        <button className="p-[18px] text-white bg-[#FB6D3A] hover:bg-[#d84646] active:scale-[.95] rounded-[20px] w-full max-w-[310px]">
          Hisobga kirish
        </button>
        <h2 className="font-semibold text-[16px]">
          Hisob mavjud emasmi?{" "}
          <Link to={"/sign-up"} className="text-[#00DF76]">
            Yaratish
          </Link>
        </h2>
      </form>
    </div>
  );
}

export default LoginComponent;
