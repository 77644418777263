import AIChat from "../components/AIChat";
import Book from "../components/Book";
import Contact from "../components/Contact";
import Dashboard from "../components/Dashboard";
import MyBooks from "../components/MyBooks";
import SelectBook from "../components/SelectBook";
import Settings from "../components/Settings";
import Statistics from "../components/Statistics";
import Login from "../pages/Login";
import SingUp from "../pages/SingUp";

export const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <SingUp />,
  },
  {
    path: "/",
    element: <Dashboard child={<SelectBook />} />,
  },
  {
    path: "/my-books",
    element: <Dashboard child={<MyBooks />} />,
  },
  {
    path: "/ai-chat",
    element: <Dashboard child={<AIChat />} />,
  },
  {
    path: "/statistics",
    element: <Dashboard child={<Statistics />} />,
  },
  {
    path: "/settings",
    element: <Dashboard child={<Settings />} />,
  },
  {
    path: "/contact",
    element: <Dashboard child={<Contact />} />,
  },
  {
    path: "/book/:id",
    element: <Dashboard child={<Book />} />,
  },
];
