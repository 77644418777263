import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoader: false,
  searchValue: "",
};

export const assistantsSlice = createSlice({
  name: "assistants",
  initialState,
  reducers: {
    loaderChange: (state, action) => {
      state.isLoader = action.payload;
    },
    searchValueChange: (state, action) => {
      state.searchValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loaderChange, searchValueChange } = assistantsSlice.actions;

export default assistantsSlice.reducer;
