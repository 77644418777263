import React from "react";
import { SearchSVG, UserSVG } from "../assets/svgs";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchValueChange } from "../../redux/slices/assistants";
import { useNavigate } from "react-router-dom";
function Navbar({ setNav }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const inputChange = (e) => {
    navigate("/");
    dispatch(searchValueChange(e.target.value));
  };
  if (pathname === "/ai-chat") return null;
  return (
    <div className="flex items-center md:gap-[1vw] gap-[5vw]">
      <button className="text-[6vw] md:hidden" onClick={() => setNav(true)}>
        ☰
      </button>
      <div className="flex items-center md:p-[1vw] p-[3vw] bg-[#e0e0e05b] md:rounded-[1vw] rounded-[2vw] w-full md:gap-[1vw] gap-[4vw]">
        <SearchSVG />
        <input
          type="search"
          placeholder="Qidirish"
          className="outline-none bg-transparent w-full md:text-[1vw] text-[4vw]"
          onChange={inputChange}
        />
      </div>
      <Link to={"/settings"}>
        <UserSVG />
      </Link>
      {/* <NotifySVG /> */}
    </div>
  );
}

export default Navbar;
