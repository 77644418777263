import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Books, CommunySVG, History, Home, Settings } from "./assets/svgs";
import Navbar from "./elements/Navbar";
import { BsRobot } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { Toaster } from "react-hot-toast";
import { getRequest } from "../utils/requests";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/auth";
function Dashboard({ child }) {
  const [nav, setNav] = useState(false);
  const [orders, setOrders] = useState([]);
  const [block, setBlock] = useState(false);
  const scrollRef = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getRequest("/user/info/my-orders", dispatch)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((log) => {
        console.log(log);
      });
    getRequest("/user", dispatch)
      .then((res) => {
        if (
          res?.message === "Unauthenticated." ||
          res?.code === "ERR_BAD_REQUEST"
        ) {
          navigate("/login");
        } else {
          dispatch(setUser(res));
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/login");
      });
  }, []);
  useEffect(() => {
    scrollRef.current.scrollTo(0, 0);
    setNav(false);
  }, [pathname]);
  useEffect(() => {
    orders.map((item) => {
      if (item.status === "block") {
        setBlock(true);
      }
    });
  }, [orders]);
  return block ? (
    <div
      className="flex items-center justify-center h-screen w-full bg-white"
      ref={scrollRef}
    >
      <h1>Sizning hisobingiz ma'lum sabablarga ko'ra bloklandi</h1>
    </div>
  ) : (
    <div className="grid md:grid-cols-[20vw_auto]">
      <div className="bg-[#503E9D]">
        <Toaster />
        <div
          className={`flex flex-col justify-between items-start  md:px-[2.8vw] px-[8.8vw] md:py-[1.56vw] py-[7.56vw] h-screen sidebar md:relative fixed bg-[#503E9D] z-[98] md:left-0 md:gap-0 gap-[5vw]  w-full ${
            nav ? "left-[0vw]" : "left-[-100vw]"
          }`}
        >
          <div className="flex">
            <img
              src={require("../components/assets/images/logo.png")}
              alt=""
              className="md:w-full w-[100%]"
            />
            <button
              className="absolute text-white text-[10vw] right-[10vw] top-[5vw] md:hidden"
              onClick={() => {
                setNav(false);
              }}
            >
              &times;
            </button>
          </div>
          <div className="grid md:gap-[.77vw] gap-[4.77vw]  font-medium tracking-[0.02vw] md:text-[0.83vw] text-[4.83vw]">
            <NavLink
              to={"/"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <Home />
                <span>Kitob tanlash va bron</span>
              </span>
            </NavLink>
            {/* 
         <NavLink
           to={"/my-books"}
           className="text-white md:text-[0.83vw] text-[4.83vw]"
         >
           <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
             <Books />
             <span>Olingan kitoblar</span>
           </span>
         </NavLink> */}
            <NavLink
              to={"/ai-chat"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <BsRobot className="md:text-[1.04vw] text-[5vw]" />
                <span>Ai bilan suhbat</span>
              </span>
            </NavLink>
            <NavLink
              to={"/statistics"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <History />
                <span>Hisobotlar</span>
              </span>
            </NavLink>
            <NavLink
              to={"/settings"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <Settings />
                <span>Sozlamalar</span>
              </span>
            </NavLink>
            <NavLink
              to={"/contact"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <CommunySVG />
                <span>Aloqa</span>
              </span>
            </NavLink>
            <NavLink
              to={"/login"}
              className="text-white md:text-[0.83vw] text-[4.83vw]"
            >
              <span className="flex items-center md:gap-[.8vw] gap-[2.8vw]">
                <BiLogOut className="md:text-[1.04vw] text-[5vw]" />
                <span>Chiqish</span>
              </span>
            </NavLink>
          </div>
          <div className="bg-[#5B49A9] md:rounded-[1.5vw] rounded-[4.5vw] text-white w-full relative overflow-hidden">
            <div className="grid md:gap-[0.4vw] gap-[3.4vw] items-start md:p-[1.5vw] p-[5.5vw]">
              <h1 className="md:text-[0.93vw] text-[6.93vw] font-medium">
                Support 24/7
              </h1>
              <h2 className="md:text-[0.83vw] text-[4.83vw] text-[#ffffff80]">
                Biz bilang bog’lanish
              </h2>
              <Link
                to="/contact"
                className="md:py-[.4vw] py-[2.4vw] md:rounded-[.4vw] rounded-[3.4vw]  md:text-[.9vw] text-[6.9vw] px-[0.52vw] bg-[#FB6D3A] md:w-[3.645vw] w-auto text-center"
              >
                Aloqa
              </Link>
            </div>
            <div className="flex justify-end">
              <img
                src={require("./assets/images/contact.png")}
                alt="contact"
                className=" md:w-[80%] w-[60%] md:block hidden"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-screen overflow-auto" ref={scrollRef}>
        <div className="p-[1.25vw] ">
          <div className="bg-white rounded-[1vw] md:p-[2.25vw] p-[3.25vw]">
            <Navbar setNav={setNav} />
            {child}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
