import React, { useEffect, useState } from "react";
import { SettingsUser } from "./assets/svgs";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../utils/requests";
import toast from "react-hot-toast";

function Settings() {
  const [selectedImage, setSelectedImage] = useState(false);
  const inputStyle =
    "border border-black md:rounded-[1vw] rounded-[3vw] bg-[#F6F6F5] md:p-[1vw] p-[4vw] md:text-[1vw] text-[4vw] w-full md:max-w-[30vw] outline-[#FB6D3A]";
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const getData = () => {
    getRequest("/user", dispatch)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    postRequest("/user/info/update-user", form, dispatch).then(() => {
      toast.success("Ma'lumotlar muvaffaqiyatli o'zgartirildi");
      getData();
    });
  };
  return (
    <div className="p-[1.25vw] ">
      <div className="bg-white rounded-[1vw] p-[2.25vw]">
        <h1 className="font-medium md:text-[2vw] text-[6vw]">
          Foydalanuvchi sozlamalari
        </h1>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col md:gap-[2vw] gap-[6vw] md:mt-[2vw] mt-[6vw] items-center"
        >
          <label htmlFor="avatar">
            <input
              type="file"
              accept="image/*"
              name="photo"
              id="avatar"
              className="hidden"
              onChange={imageChange}
            />

            {selectedImage ? (
              <img
                src={URL.createObjectURL(selectedImage)}
                className="md:w-[14vw] md:h-[14vw] w-[50vw] h-[50vw] rounded-full object-cover"
                alt=""
              />
            ) : data?.photo ? (
              <img
                src={`https://ebook.firstcoders.site${data?.photo}`}
                className="md:w-[14vw] md:h-[14vw] w-[50vw] h-[50vw] rounded-full object-cover"
                alt=""
              />
            ) : (
              <SettingsUser />
            )}
          </label>
          <input
            type="text"
            className={inputStyle}
            placeholder="Foydalanuvchi nomi"
            defaultValue={data?.user_name}
            name="user_name"
            minLength={5}
          />
          <input
            type="text"
            className={inputStyle}
            defaultValue={data?.full_name}
            name="full_name"
            placeholder="Ismi va Familiyasi"
          />
          <input
            type="text"
            className={inputStyle}
            defaultValue={data?.email}
            name="email"
            placeholder="Elektron pochta manzili"
          />
          <input
            type="text"
            className={inputStyle}
            placeholder="Maxfiy so’z"
            name="password"
          />
          {/* <input
            type="password"
            className={inputStyle}
            placeholder="Maxfiy so'z (takroran)"
          /> */}
          <button className=" text-white bg-[#FB6D3A] hover:bg-[#d84646] active:scale-[.95]  md:rounded-[1vw] rounded-[3vw]  md:p-[1vw] p-[4vw] md:text-[1vw] text-[4vw] w-full md:max-w-[30vw]">
            Ma'lumotlarning o'zgartirish
          </button>
        </form>
      </div>
    </div>
  );
}

export default Settings;
