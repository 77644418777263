import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequest } from "../utils/requests";
import { useDispatch } from "react-redux";

function MyBooks() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getData = () => {
    getRequest("/posts", dispatch)
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <h1 className="font-medium md:text-[2vw] text-[6vw] md:mt-[3vw] mt-[6vw]">
        Olingan kitoblar
      </h1>
      <div className="grid md:grid-cols-3 grid-cols-2 md:gap-[1vw] gap-[3vw] md:mt-[2vw] mt-[4vw]">
        {data?.slice(0, 4)?.map((item, i) => {
          return (
            <Link
              to={"/book/" + item?.id}
              key={i}
              className="w-full md:h-[18vw] h-[48vw] rounded-[2vw] overflow-hidden relative md:p-[1vw] p-[3vw] flex items-end"
            >
              <img
                src={
                  "https://picsum.photos/500/50" +
                  Math.floor(Math.random() * 10)
                }
                alt=""
                className="absolute w-full h-full object-cover top-0 left-0"
              />
              <div className="flex flex-col relative z-[4] text-white backdrop-blur-[1vw] md:p-[1vw] p-[3vw] md:rounded-[.5vw] rounded-[2.5vw] w-full  bg-[#00000062]">
                <h1 className="md:text-[1vw] text-[4vw] font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                  {item?.title}
                </h1>
                <h2 className="md:text-[.8vw] text-[2.8vw] whitespace-nowrap overflow-hidden text-ellipsis">
                  {item?.body}
                </h2>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default MyBooks;
