import React, { useEffect, useState } from "react";
import { InviteSVG } from "./assets/svgs";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../utils/requests";
import toast from "react-hot-toast";
import { useAuth } from "../redux/selectors";

function Book() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const getData = () => {
    getRequest("/user/info/show-book/" + id, dispatch)
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Havoladan nusxa olindi!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const createOrder = () => {
    postRequest(
      "/user/info/create-order",
      {
        user_id: user?.id,
        book_id: data?.id,
      },
      dispatch
    )
      .then((res) => {
        toast.success("Kitob bron qilindi!");
      })
      .catch((res) => {
        toast.error("Tizimda xatolik qayta urinib ko'ring!");
      });
  };
  return (
    <div className="bg-white rounded-[1vw] p-[2.25vw] relative">
      <Link to={"/"}>
        <div className="flex items-center gap-2 md:text-[1.5vw] text-[4.5vw]  absolute left-0 border border-black rounded-full p-[.4vw] ">
          <IoMdArrowBack />
        </div>
      </Link>
      <h1 className="font-medium md:text-[2vw] text-[6vw] md:mt-[3vw] mt-[6vw] uppercase">
        {data?.name} ({data?.writer})
      </h1>
      <div className="grid md:grid-cols-[4fr_2fr] gap-[4vw] mt-[2vw] items-start">
        <div className="grid gap-[2vw]">
          <img
            src={
              data.image !== "false" && data?.image !== null
                ? "https://ebook.firstcoders.site" + data?.image
                : "https://img.freepik.com/free-vector/hand-drawn-flat-design-stack-books-illustration_23-2149341898.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1713225600&semt=sph"
            }
            alt={data?.name}
            className="w-full md:h-[30vw] h-[60vw] rounded-[2vw] object-cover top-0 left-0"
          />
          <div className="flex items-center justify-between border-b border-[#cecece] pb-[2vw]">
            <div className="flex items-center gap-[2vw]">
              <button
                className="md:w-[14vw] md:py-[.4vw] py-[1vw] w-[38vw] text-white md:text-[1vw] text-[4vw] rounded-[2vw] md:rounded-[.6vw] bg-[#FB6D3A]"
                onClick={createOrder}
              >
                Band qilish (Bron)
              </button>

              {data?.pdf_link && (
                <a
                  href={"https://ebook.firstcoders.site" + data?.pdf_link}
                  download
                  target="_blank"
                >
                  <button className="md:w-[14vw] md:py-[.4vw] py-[1vw] w-[38vw] text-white md:text-[1vw] text-[4vw] rounded-[2vw] md:rounded-[.6vw] bg-[#503E9D]">
                    PDF yuklab olish
                  </button>
                </a>
              )}
            </div>
            <button onClick={copyToClipboard}>
              <InviteSVG />
            </button>
          </div>
          <p className="md:text-[1vw] text-[3vw] md:w-[80%] w-full font-medium">
            Kitob xaqida batafsil:
            <br />
            Sahifalar soni: {data?.pages_count} <br />
            Mavjud nusxalar soni: {data?.copy_count} <br />
            Chop etilgan yili: {data?.date_book} <br />
            Muallif: {data?.writer} <br />
            Izoh: {data?.comment} <br />
          </p>
        </div>
        <div className="grid">
          <h1 className="font-medium md:text-[2vw] text-[6vw] mb-[3vw]">
            Biz bilan bog'laning
          </h1>
          <div className="bg-[#5B49A9] md:rounded-[1.5vw] rounded-[4.5vw] text-white w-full relative overflow-hidden">
            <div className="grid md:gap-[0.4vw] gap-[3.4vw] items-start md:p-[1.5vw] p-[5.5vw]">
              <h1 className="md:text-[0.93vw] text-[6.93vw] font-medium">
                Support 24/7
              </h1>
              <h2 className="md:text-[0.83vw] text-[4.83vw] text-[#ffffff80]">
                Biz bilang bog’lanish
              </h2>
              <button className="md:py-[.4vw] py-[2.4vw] md:rounded-[.4vw] rounded-[3.4vw]  md:text-[.9vw] text-[6.9vw] px-[0.52vw] bg-[#FB6D3A] md:w-[3.645vw] w-auto">
                Aloqa
              </button>
            </div>
            <div className="flex justify-end">
              <img
                src={require("./assets/images/contact.png")}
                alt="contact"
                className=" md:w-[80%] w-[60%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Book;
