import React from "react";

function Contact() {
  const inputStyle =
    "border border-black md:rounded-[1vw] rounded-[3vw] bg-[#F6F6F5] md:p-[1vw] p-[4vw] md:text-[1vw] text-[4vw] w-full  outline-[#FB6D3A]";
  return (
    <div className="p-[1.25vw] ">
      <div className="bg-white rounded-[1vw] p-[2.25vw]">
        <h1 className="font-medium md:text-[2vw] text-[6vw]">Aloqa</h1>
        <div className="grid md:grid-cols-2 gap-[5vw]">
          <div className="flex flex-col md:gap-[2vw] gap-[6vw] md:mt-[2vw] mt-[6vw]">
            <p className="font-medium md:text-[1.2vw] flex gap-[1vw]">
              Email:{" "}
              <a
                href="farruhlee02@gmail.com"
                target="_blank"
                className="font-normal text-blue-500 underline"
              >
                NamMMQI@gmail.com
              </a>
            </p>
            <p className="font-medium md:text-[1.2vw] flex gap-[1vw]">
              Telefon:{" "}
              <a
                href="tel:+998(69)63-23-133"
                target="_blank"
                className="font-normal text-blue-500 underline"
              >
                +998(69)63-23-133
              </a>
            </p>
            <p className="font-medium md:text-[1.2vw] flex gap-[1vw]">
              Telefon:{" "}
              <a
                href="tel:+998(69)63-23-133"
                target="_blank"
                className="font-normal text-blue-500 underline"
              >
                +998(90)660-60-60
              </a>
            </p>
            <p className="font-medium md:text-[1.2vw] flex gap-[1vw]">
              Manzil:{" "}
              <a
                href="https://www.google.com/maps/place/Namangan+muhandislik-qurilish+instituti+2-bino,+%D0%9D%D0%B0%D0%BC%D0%B0%D0%BD%D0%B3%D0%B0%D0%BD/@41.0091476,71.6215747,16z/data=!4m6!3m5!1s0x38bb4b4e4d08cbb5:0x4846bdb82817ac1e!8m2!3d41.0071443!4d71.6325178!16s%2Fg%2F11thn03d3_?entry=ttu"
                target="_blank"
                className="font-normal text-blue-500 underline"
              >
                Namangan shahar, Do'stlik, I.Karimov ko'chasi
              </a>
            </p>

            {/* <input
            type="text"
            className={inputStyle}
            placeholder="Foydalanuvchi nomi"
          />
          <input
            type="text"
            className={inputStyle}
            placeholder="Ismi va Familiyasi"
          />
          <input
            type="text"
            className={inputStyle}
            placeholder="Elektron pochta manzili"
          />
          <input type="text" className={inputStyle} placeholder="Maxfiy so’z" />
          <input
            type="password"
            className={inputStyle}
            placeholder="Maxfiy so'z (takroran)"
          />
          <button className=" text-white bg-[#FB6D3A] hover:bg-[#d84646] active:scale-[.95]  md:rounded-[1vw] rounded-[3vw]  md:p-[1vw] p-[4vw] md:text-[1vw] text-[4vw] w-full md:max-w-[30vw]">
            Ma'lumotlarning o'zgartirish
          </button> */}
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6021.472330068435!2d71.62157470458152!3d41.009147565245236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bb4b4e4d08cbb5%3A0x4846bdb82817ac1e!2zTmFtYW5nYW4gbXVoYW5kaXNsaWstcXVyaWxpc2ggaW5zdGl0dXRpIDItYmlubywg0J3QsNC80LDQvdCz0LDQvQ!5e0!3m2!1sru!2s!4v1708848429019!5m2!1sru!2s"
           className="w-full md:h-[20vw] h-[60vw]"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
